import React from "react";
import { LoginPage } from "../../pages";
import { Link } from "../../components/Link/Link";
import { DDBlueIcon } from "./icons/DDBlueIcon";

export const SignUpFlowLayout = ({
  showLogin,
  children,
}: {
  showLogin?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="border-b border-grey w-full hidden md:flex flex-row items-center justify-between mb-8">
        <DDBlueIcon className="my-4 mx-8" />
        {showLogin && (
          <Link to={LoginPage} className="mx-8 text-black font-medium">
            Login
          </Link>
        )}
      </div>
      <div className="flex justify-center overflow-hidden mb-4">
        <main className="flex flex-col items-center bg-white text-dark-grey w-full px-5 md:px-0 sm:max-w-md">
          <div className="relative w-full pb-64">{children}</div>
        </main>
      </div>
    </>
  );
};
