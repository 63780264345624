import { FormikProps, useField } from "formik";
import React from "react";
import CheckBoxChecked from "../../icons/CheckBoxChecked";
import CheckBoxEmpty from "../../icons/CheckBoxEmpty";

type CheckboxProps = {
  labelText?: string;
} & FormikProps<{}>;

export const Checkbox = ({ labelText, ...props }: CheckboxProps) => {
  const [field] = useField("policyConfirmation");
  return (
    <div>
      <label className="label-xl flex">
        <div className="relative">
          <input
            type="checkbox"
            className="opacity-0 absolute"
            tabIndex={0}
            checked={field.value}
            {...field}
            {...props}
          />
          {field.value ? <CheckBoxChecked /> : <CheckBoxEmpty />}
        </div>
        {labelText && <span className="body-m m-0 ml-3">{labelText}</span>}
      </label>
    </div>
  );
};
