import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as yup from "yup";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { SignUpFlowLayout } from "../SignUpFlowLayout";
import { SignUpFlowHeader } from "../components/SignUpFlowHeader";
import { Link } from "../../../components/Link/Link";
import { LoginPage } from "../../../pages";
import { SocialButton } from "../../../components/SocialLogin/SocialButton";
import { FacebookIcon } from "../../../icons/FacebookIcon";
import { GoogleIcon } from "../../../icons/GoogleIcon";
import { isBrowser } from "../../../utils/ssr";
import { useGoogleSignIn } from "../../../components/SocialLogin/hooks/useGoogleSignIn";
import { useFacebookSignIn } from "../../../components/SocialLogin/hooks/useFacebookSignIn";
import { navigate } from "gatsby";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import Loading from "../../../components/Loading/Loading";
import { useSocialLoginResult } from "../../../components/SocialLogin/hooks/useSocialLoginResult";
import { ErrorBox } from "../../../components/LoginPage/components/ErrorBox";
import { t } from "@lingui/macro";
import { Field, Form, Formik } from "formik";
import { Button } from "../../../components/FormElement/Button";
import { EmailInput, emailRegex } from "../../../components/FormElement/EmailInput";
import { SocialLoginDivider } from "../../../components/SocialLogin/SocialLoginDivider";
import { SocialProvider } from "../../../components/SocialLogin/types";
import { getLocale } from "../../../utils/getLocale";
import { Locale } from "../../../types/Locale";
import { MembershipSubscriptionProvider } from "../../../types/graphql-global-types";
import { navigateToMyAccount } from "../utils/navigateToMyAccount";

const validationSchema = (tt: ReturnType<typeof useTranslations>) =>
  yup.object({
    email: yup
      .string()
      .trim()
      .matches(emailRegex, tt.pleaseEnterAValidEmail)
      .email(tt.pleaseEnterAValidEmail)
      .required(tt.emailIsRequired),
  });

const AccountSignUpOptionsPage = () => {
  const tt = useTranslations();

  const {
    loggedIn,
    user,
    loading: authLoading,
    premium,
    subscription,
    error: signupError,
  } = useContext(LoginContext);

  const isUserAlreadyExist = signupError?.message.includes("the email is already registered");

  const { handleSocialLoginResult, socialLoginError, firebaseSocialAuthInfo } = useSocialLoginResult();

  const { handleGoogleLogin } = useGoogleSignIn();
  const { handleFacebookLogin } = useFacebookSignIn();

  const queryParams = useMemo(() => new URLSearchParams((isBrowser() && window?.location.search) || ""), []);

  const handleOnClick = useCallback(
    (values: { email: string }) => {
      navigate(`../details?${queryParams.toString()}`, {
        replace: false,
        state: { emailId: values.email },
      });
    },
    [queryParams]
  );

  useEffect(() => {
    handleSocialLoginResult("signup");
  }, [handleSocialLoginResult]);

  // Handle logged in user
  useEffect(() => {
    if (user && !subscription && loggedIn && !authLoading) {
      if (locale === Locale.EN) {
        navigate(`../payment-v4?${queryParams.toString()}`, {
          replace: true,
        });
      } else {
        navigate(`../plan?${queryParams.toString()}`, {
          replace: false,
          state: { fromPrevious: true },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscription, firebaseSocialAuthInfo, queryParams, premium, loggedIn, authLoading]);

  const locale = useMemo(() => getLocale(), []);

  useEffect(() => {
    if (
      subscription &&
      user &&
      loggedIn &&
      !authLoading &&
      firebaseSocialAuthInfo?.email &&
      firebaseSocialAuthInfo?.providerInfo
    ) {
      if (
        locale === Locale.ES &&
        subscription?.provider !== MembershipSubscriptionProvider.STRIPE &&
        subscription?.provider !== MembershipSubscriptionProvider.PAYPAL
      ) {
        navigateToMyAccount(locale);
      } else {
        navigate("/");
      }
    }
  }, [subscription, user, loggedIn, authLoading, firebaseSocialAuthInfo, locale]);

  return authLoading ? (
    <Loading />
  ) : (
    <>
      <SEOReactHelmet title={tt.socialSignUpSEOTitle || "Sign Up Options"} noindex />
      <SignUpFlowLayout showLogin={true}>
        <div className="flex flex-col items-center">
          <SignUpFlowHeader title={tt.socialSignUpTitle}>
            <p className="text-black opacity-75 self-start m-0 mb-8 mt-1 body-l">{tt.socialSignUpText}</p>
          </SignUpFlowHeader>

          {socialLoginError && <ErrorBox className="mb-8" firebaseError={socialLoginError} />}
          {isUserAlreadyExist && (
            <ErrorBox className="mb-8">
              {tt.emailAlreadyRegistered} <br />
              <Link className="text-red underline" to={LoginPage}>
                {tt.logIntoThatAccount}
              </Link>{" "}
              {tt.tryDifferentEmail}
            </ErrorBox>
          )}

          <div className="w-full">
            <Formik
              validationSchema={validationSchema(tt)}
              initialValues={{ email: "" }}
              onSubmit={handleOnClick}
              validateOnMount={true}
            >
              {({ handleSubmit, isValid }) => (
                <Form onKeyDown={(e) => e.key === "Enter" && handleSubmit()}>
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    autoFocus={true}
                    component={EmailInput}
                    placeholderText={tt.emailLabel}
                  />
                  <Button
                    type="submit"
                    dataTestid="continue"
                    label={tt.nextButton}
                    disabled={!isValid}
                    className="w-full bg-green"
                  />
                </Form>
              )}
            </Formik>
          </div>
          <SocialLoginDivider />

          <div className="w-full">
            <SocialButton
              provider={SocialProvider.FACEBOOK}
              icon={<FacebookIcon />}
              onClick={handleFacebookLogin}
              dataTestid="facebook-sso-button"
            />

            <SocialButton
              provider={SocialProvider.GOOGLE}
              icon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              className="mt-3"
              dataTestid="google-sso-button"
            />

            {/* Already member */}
            <div className="text-base mt-5 text-center">
              {tt.alreadyHaveAnAccount}
              <Link to={LoginPage} className="underline text-green ml-2">
                {tt.signInHere}
              </Link>
            </div>
          </div>
        </div>
      </SignUpFlowLayout>
    </>
  );
};

function useTranslations() {
  return {
    socialSignUpSEOTitle: t({
      id: "SignUpFlow.accountSignUpSEOTitle",
      message: "Credentials",
    }),
    socialSignUpTitle: t({
      id: "SignUpFlow.socialSignUpTitle",
      message: "Start your free trial",
    }),
    socialSignUpText: t({
      id: "SignUpFlow.socialSignUpText",
      message: "Create an account to get started.",
    }),
    emailLabel: t({
      id: "SignUpFlow.emailLabel",
      message: "E-mail (Please enter correctly.)",
    }),
    pleaseEnterAValidEmail: t({
      id: "SignUpFlow.pleaseEnterAValidEmail",
      message: `Please enter a valid email`,
    }),
    emailIsRequired: t({
      id: "SignUpFlow.emailIsRequired",
      message: "Email is required",
    }),
    nextButton: t({
      id: "SignUpFlow.nextButton",
      message: "Continue",
    }),
    alreadyHaveAnAccount: t({
      id: "SignUpFlow.alreadyHaveAnAccount",
      message: "Already have an account?",
    }),
    signInHere: t({
      id: "SignUpFlow.signInHere",
      message: "Sign in here",
    }),
    emailAlreadyRegistered: t({
      id: "SignUpFlow.emailAlreadyRegistered",
      message: "This email is already registered",
    }),
    logIntoThatAccount: t({
      id: "SignUpFlow.logIntoThatAccount",
      message: "Log into that account",
    }),
    tryDifferentEmail: t({
      id: "SignUpFlow.tryDifferentEmail",
      message: "or try using a different email.",
    }),
  };
}

export default React.memo(AccountSignUpOptionsPage);
