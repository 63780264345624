import { gql, useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useMemo } from "react";
import {
  MembershipSubscriptionProvider,
  MembershipSubscriptionStatus,
} from "../../../types/graphql-global-types";
import { getLocale } from "../../../utils/getLocale";
import { useNavigate } from "../../../components/Link/Link";
import Loading from "../../../components/Loading/Loading";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { LoginPage } from "../../../pages";

const GET_STRIPE_BILLING_PORTAL_LINK = gql`
  query GetStripeBillingPortalLink($returnURL: String!) {
    stripeCustomerPortal(returnURL: $returnURL) {
      url
    }
  }
`;

const StripePortalRedirectPage = () => {
  const { loading: loginLoading, error: loginError, subscription, loggedIn } = useContext(LoginContext);
  const navigate = useNavigate();

  const locale = useMemo(() => getLocale(), []);

  const [getStripeBillingPortalLink, { loading, data, error }] = useLazyQuery(GET_STRIPE_BILLING_PORTAL_LINK);

  useEffect(() => {
    if (!loading && data?.stripeCustomerPortal?.url) {
      window.location.href = data.stripeCustomerPortal.url;
    }
  }, [loading, data]);

  useEffect(() => {
    if (!loginLoading && loggedIn) {
      let returnUrl: string;
      const baseUrl = process.env.GATSBY_WEBSITE_URL || window.location.origin;
      switch (locale) {
        case "sv":
          returnUrl = baseUrl + "/se/medlem/mitt-konto";
          break;
        case "es":
          returnUrl = baseUrl + "/es/mi-cuenta";
          break;
        default:
          returnUrl = baseUrl + "/my-account";
          break;
      }

      if (subscription?.status === MembershipSubscriptionStatus.ACTIVE) {
        getStripeBillingPortalLink({ variables: { returnURL: returnUrl } });
      } else if (subscription?.status === MembershipSubscriptionStatus.NEW) {
        switch (locale) {
          case "sv":
            returnUrl = baseUrl + "/se/bli-medlem/payment?cancel=true";
            break;
          case "es":
            returnUrl = baseUrl + "/es/hazte-miembro/payment?cancel=true";
            break;
          default:
            returnUrl = baseUrl + "/become-member/payment?cancel=true";
            break;
        }
        window.location.href = returnUrl;
      } else if (
        subscription?.status === MembershipSubscriptionStatus.EXPIRED ||
        subscription?.status === MembershipSubscriptionStatus.CANCELLED ||
        subscription?.status === MembershipSubscriptionStatus.INVALID
      ) {
        switch (locale) {
          case "sv":
            returnUrl = baseUrl + "/se/bli-medlem/payment";
            break;
          case "es":
            returnUrl = baseUrl + "/es/hazte-miembro/payment";
            break;
          default:
            returnUrl = baseUrl + "/become-member/payment";
            break;
        }
        window.location.href = returnUrl;
      } else if (subscription?.provider !== MembershipSubscriptionProvider.STRIPE) {
        window.location.href = returnUrl;
      }
    } else if (!loginLoading && !loggedIn) {
      navigate({
        to: LoginPage,
        query: {
          redirectTo: document.location.href,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginLoading, loggedIn]);

  if (!loginLoading && !loading && (loginError || error)) {
    console.log("loginError", loginError);
    console.log("error", error);
    throw Error("Couldn't redirect to Stripe portal");
  } else {
    return <Loading />;
  }
};

export default React.memo(StripePortalRedirectPage);
