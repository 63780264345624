import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { useMutation } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { navigate } from "gatsby";
import CreateUser from "../../../graphql/CreateUser.graphql";
import useGeoCountryCode from "../../../graphql/useGeoCountryCode/useGeoCountryCode";
import { LoginPage, TermsPage, TermsSection } from "../../../pages";
import { getLocale } from "../../../utils/getLocale";
import { getLocaleBCP47 } from "../../../utils/getLocaleBCP47";
import { sendEvent } from "../../../utils/gtm";
import { isBrowser } from "../../../utils/ssr";
import { EmailInput, emailRegex } from "../../../components/FormElement/EmailInput";
import PasswordInput from "../../../components/FormElement/PasswordInput";
import { Link } from "../../../components/Link/Link";
import { ErrorBox } from "../../../components/LoginPage/components/ErrorBox";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { SignUpFlowLayout } from "../SignUpFlowLayout";
import { SignUpFlowHeader } from "../components/SignUpFlowHeader";
import { SignUpFlowLocationType } from "../types/SignUpFlowLocationType";
import { removeUnnecessarySpaces } from "../utils/removeUnnecessarySpaces";
import { Button } from "../../../components/FormElement/Button";
import { TextInput } from "../../../components/FormElement/TextInput";
import { Checkbox } from "../../../components/FormElement/Checkbox";
import { Locale } from "../../../types/Locale";

const validationSchema = (tt: ReturnType<typeof useTranslations>) =>
  yup.object({
    email: yup
      .string()
      .trim()
      .matches(emailRegex, tt.pleaseEnterAValidEmail)
      .email(tt.pleaseEnterAValidEmail)
      .required(tt.emailIsRequired),
    password: yup
      .string()
      .trim()
      .min(6, tt.passwordEnterAtLeast(6))
      .required(tt.fieldIsRequired(tt.passwordLabel)),
    firstName: yup
      .string()
      .trim()
      .min(2, tt.pleaseEnterAValid(tt.firstNameLabel.toLowerCase()))
      .required(tt.fieldIsRequired(tt.firstNameLabel.toLowerCase())),
    lastName: yup
      .string()
      .trim()
      .min(2, tt.pleaseEnterAValid(tt.lastNameLabel.toLowerCase()))
      .required(tt.fieldIsRequired(tt.lastNameLabel.toLowerCase())),
    policyConfirmation: yup.boolean().oneOf([true], "required"),
  });

const AccountEmailCreatePage = ({ location }: { location?: SignUpFlowLocationType }) => {
  const tt = useTranslations();
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState<string | null>(null);
  const [, setInternalError] = useState<boolean | null>(null);

  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const queryParams = useMemo(() => new URLSearchParams((isBrowser() && window?.location.search) || ""), []);

  const [createUser] = useMutation(CreateUser);

  const { loginWithPassword, logout, loggedIn, loading: userLoading } = useContext(LoginContext);

  const locale = useMemo(() => getLocale(), []);
  const localeBCP47 = useMemo(() => getLocaleBCP47(), []);

  const { data: geoCountryCode } = useGeoCountryCode();

  // Handle login redirection
  useEffect(() => {
    if (!userLoading && loggedIn === true && loggingIn === true) {
      if (locale === Locale.EN) {
        navigate(`../payment-v4?${queryParams.toString()}`, {
          replace: true,
        });
      } else {
        navigate(`../plan?${queryParams.toString()}`, {
          replace: false,
          state: { fromPrevious: true },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, locale, loggedIn, loggingIn, queryParams, userLoading]);

  // Handle geolocation country code result
  useEffect(() => {
    if (geoCountryCode) {
      setCountry(geoCountryCode);
    }
  }, [geoCountryCode]);

  // Handle submit
  const handleRegisterAccount = useCallback(
    async (values: any) => {
      if (loading) {
        return null;
      }

      const communicationPrefs = {
        subscribeMemberEmails: true,
        subscribeNewsletterEmails: true,
      };

      const createAccountEvent = {
        eventName: "signup",
        action: "create_account",
        payload: {
          success: true,
          tax_residence: country,
          locale: locale!,
          is_subscribed_to_member_emails: communicationPrefs.subscribeMemberEmails,
          is_subscribed_to_newsletter: communicationPrefs.subscribeNewsletterEmails,
          provider: "email",
        },
      } as const;

      setLoading(true);
      setEmailExistsError(null);
      setInternalError(null);

      if (window?.localStorage.getItem("paymentLoading")) {
        window.localStorage.removeItem("paymentLoading");
      }

      try {
        await logout();

        const { data } = await createUser({
          variables: {
            firstName: removeUnnecessarySpaces(values.firstName),
            lastName: removeUnnecessarySpaces(values.lastName),
            email: values.email,
            password: values.password,
            taxResidence: country,
            communicationPrefs,
            language: localeBCP47,
          },
        });

        const token = await data.createUser.token;

        if (!token) {
          setInternalError(true);
          throw new Error("Invalid token");
        } else if (token) {
          sendEvent(createAccountEvent);

          setLoggingIn(true);

          await loginWithPassword(values.email, values.password, true);
        }
      } catch (e) {
        //@ts-ignore
        const errorMessage = e.message;

        // https://github.com/dietdoctor/dietdoctor-api/blob/e02c39a8a42b578527ad11f9d8c4a2000a96d3b9/resolver/mutation_user.go#L20
        if (errorMessage.includes("email already registered") || errorMessage.includes("user already exists")) {
          setEmailExistsError(values.email);
        }

        // https://github.com/dietdoctor/dietdoctor-api/blob/e02c39a8a42b578527ad11f9d8c4a2000a96d3b9/resolver/mutation_user.go#L69
        if (errorMessage.includes("unable to create user in user service")) {
          setInternalError(true);
        }

        sendEvent({
          ...createAccountEvent,
          payload: {
            ...createAccountEvent.payload,
            success: false,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [loading, country, locale, logout, createUser, localeBCP47, loginWithPassword]
  );

  return (
    <>
      <SEOReactHelmet title={tt.accountSignUpSEOTitle || "Account Sign Up"} noindex />
      <SignUpFlowLayout showLogin={true}>
        <div className="flex flex-col items-center">
          <SignUpFlowHeader title={tt.accountSignUpTitle}>
            <p className="text-black opacity-75 self-start m-0 mb-8 mt-1 body-l">{tt.accountSignUpText}</p>
          </SignUpFlowHeader>

          {/* Errors */}
          {/* {internalError && <ErrorBox>{translationData.internalErrorMessage}</ErrorBox>} */}

          {emailExistsError && (
            <ErrorBox className="mb-5">
              {tt.emailAlreadyRegistered} <br />
              <Link className="text-red underline" to={LoginPage}>
                {tt.logIntoThatAccount}
              </Link>{" "}
              {tt.tryDifferentEmail}
            </ErrorBox>
          )}

          {/* {loggedIn && !loggingIn && <ErrorBox>{'Already logged in!'}</ErrorBox>} */}

          <Formik
            validationSchema={validationSchema(tt)}
            validateOnMount={true}
            initialValues={{
              firstName: "",
              lastName: "",
              email: location?.state?.emailId || "",
              password: "",
              policyConfirmation: false,
            }}
            onSubmit={handleRegisterAccount}
          >
            {({ handleSubmit, isValid }) => (
              <Form
                method="POST"
                className="w-full"
                autoComplete="off"
                onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
              >
                <Field
                  name="firstName"
                  id="firstName"
                  autoFocus={true}
                  component={TextInput}
                  placeholderText={tt.firstNameLabel}
                />
                <Field name="lastName" id="lastName" component={TextInput} placeholderText={tt.lastNameLabel} />
                <Field type="email" name="email" component={EmailInput} placeholderText={tt.emailLabel} />
                <Field name="password" component={PasswordInput} placeholderText={tt.passwordLabel} />

                <div className="flex items-center flex-row mb-8">
                  <Field type="checkbox" name="policyConfirmation" component={Checkbox} />
                  <div className="m-0 ml-2 body-s">{tt.agreeToTOS}</div>
                </div>
                <Button
                  type="submit"
                  dataTestid="continue"
                  disabled={!isValid}
                  loading={loading}
                  label={tt.next}
                  className="w-full bg-green"
                />
              </Form>
            )}
          </Formik>
        </div>
      </SignUpFlowLayout>
    </>
  );
};

function useTranslations() {
  return {
    accountSignUpSEOTitle: t({
      id: "SignUpFlow.accountSignUpSEOTitle",
      message: "Account details",
    }),
    accountSignUpTitle: t({
      id: "SignUpFlow.accountSignUpTitle",
      message: "Finish signing up",
    }),
    accountSignUpText: t({
      id: "SignUpFlow.accountSignUpText",
      message: "Two more steps and you’re done!",
    }),
    emailAlreadyRegistered: t({
      id: "SignUpFlow.emailAlreadyRegistered",
      message: "This email is already registered",
    }),
    logIntoThatAccount: t({
      id: "SignUpFlow.logIntoThatAccount",
      message: "Log into that account",
    }),
    tryDifferentEmail: t({
      id: "SignUpFlow.tryDifferentEmail",
      message: "or try using a different email.",
    }),
    firstNameLabel: t({
      id: "SignUpFlow.firstNameLabel",
      message: "First name",
    }),
    lastNameLabel: t({
      id: "SignUpFlow.lastNameLabel",
      message: "Last name",
    }),
    emailLabel: t({
      id: "SignUpFlow.emailLabel",
      message: "E-mail (Please enter correctly.)",
    }),
    pleaseEnterAValid: (label: string) =>
      t({
        id: "SignUpFlow.inputField.pleaseEnterAValid",
        message: `Please enter a valid ${label}`,
      }),
    fieldIsRequired: (label: string) =>
      t({
        id: "SignUpFlow.inputField.fieldIsRequired",
        message: `${label} is required`,
      }),
    pleaseEnterAValidEmail: t({
      id: "SignUpFlow.inputField.pleaseEnterAValidEmail",
      message: `Please enter a valid email`,
    }),
    emailIsRequired: t({
      id: "SignUpFlow.inputField.emailIsRequired",
      message: "Email is required",
    }),
    passwordLabel: t({
      id: "SignUpFlow.passwordLabel",
      message: "Password",
    }),
    passwordEnterAtLeast: (number: number) =>
      t({
        id: "SignUpFlow.inputField.thePasswordMustBeAtLeast6Characters",
        message: `The password must be at least ${number} characters`,
      }),
    agreeToTOS: (
      <Trans id="SignUpFlow.agreeToTOS">
        I agree to Diet Doctor’s{" "}
        <Link to={TermsPage} query={{ section: TermsSection.TERMS }} className="text-black font-medium">
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          to={TermsPage}
          query={{ section: TermsSection.PRIVACY_POLICY }}
          className="text-black font-medium"
        >
          Privacy Policy
        </Link>
        .
      </Trans>
    ),
    next: t({
      id: "SignUpFlow.next",
      message: "Next",
    }),
  };
}

export default React.memo(AccountEmailCreatePage);
