import * as React from "react";

export function DDBlueIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height={56} viewBox="0 0 34 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 45.298v-5.662h11.333c4.509 0 8.833-1.79 12.021-4.975a16.98 16.98 0 000-24.023 17.006 17.006 0 00-12.02-4.976H0V0h11.333a22.676 22.676 0 0116.028 6.634 22.64 22.64 0 010 32.03 22.675 22.675 0 01-16.028 6.634H0zm11.333-33.973c3.006 0 5.889 1.193 8.014 3.317a11.32 11.32 0 010 16.015 11.338 11.338 0 01-8.014 3.317H0v-22.65h11.333zM5.667 28.31h5.666a5.66 5.66 0 100-11.325H5.667v11.325z"
        fill="#0E3478"
      />
    </svg>
  );
}
