import React, { useContext, useEffect, useMemo, useRef } from "react";
import { t } from "@lingui/macro";
import Loading from "../../../components/Loading/Loading";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { SignUpFlowLayout } from "../SignUpFlowLayout";
import { SignUpFlowLocationType } from "../types/SignUpFlowLocationType";
import { navigateToCredentials } from "../utils/navigateToCredentials";
import { SignUpFlowHeader } from "../components/SignUpFlowHeader";
import { PaymentError } from "../components/PaymentError";
import { PaymentMethodSelect } from "../components/PaymentMethodSelect";
import { SubscriptionPlanOverview } from "../components/SubscriptionPlanOverview";
import StripeSubscribeSection from "../components/StripeSubscribeSection";
import PayPalSubscribeSection from "../components/PayPalSubscribeSection";
import { PaymentProvider } from "../contexts/PaymentProvider";
import { getLocale } from "../../../utils/getLocale";
import { Locale } from "../../../types/Locale";
import { navigate } from "gatsby";

const SubscriptionPaymentPage = ({ location }: { location?: SignUpFlowLocationType }) => {
  const tt = useTranslations();
  const { loading: loginLoading, user, loggedIn } = useContext(LoginContext);
  const paymentDetailsRef = useRef<HTMLDivElement>(null);

  const locale = useMemo(() => getLocale(), []);

  const queryParams = useMemo(() => location?.search || "", [location]);

  // Handle discount param
  useEffect(() => {
    if (locale === Locale.EN && new RegExp(/[?&]d=y($|&)/).test(queryParams)) {
      navigate(`../payment-v3${queryParams}`, {
        replace: true,
      });
    }
  }, [locale, queryParams]);

  // Handle logged out user
  useEffect(() => {
    if (!loginLoading && !user && !loggedIn) {
      navigateToCredentials("account");
    }
  }, [loggedIn, loginLoading, user]);

  // Full screen loading
  if (loginLoading) {
    return (
      <div className="bg-white min-h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <SEOReactHelmet title={tt.paymentSEOTitle || "Payment"} noindex />
      <PaymentProvider>
        <SignUpFlowLayout>
          <div className="flex flex-col items-center">
            <SignUpFlowHeader title={tt.paymentTitle}>
              <p className="text-black opacity-75 self-start m-0 mt-1 body-l">{tt.paymentText}</p>
            </SignUpFlowHeader>
          </div>

          {/* Plan overview / summary */}
          <SubscriptionPlanOverview location={location} />

          {/* Error box */}
          <PaymentError />

          {/* Select payment method */}
          <div className="m-0 mt-8 body-l font-medium">{tt.setUpPaymentForLater}</div>
          <PaymentMethodSelect
            debitCreditText={tt.debitCredit}
            paypalText={tt.paypal}
            scrollToRef={paymentDetailsRef}
          />

          {/* Payment method input, PayPal & Stripe */}
          <div ref={paymentDetailsRef}>
            {user?.userId && (
              <>
                <StripeSubscribeSection />
                <PayPalSubscribeSection />
              </>
            )}
          </div>
        </SignUpFlowLayout>
      </PaymentProvider>
    </>
  );
};

function useTranslations() {
  return {
    paymentSEOTitle: t({
      id: "SignUpFlow.paymentSEOTitle",
      message: "Payment",
    }),
    paymentTitle: t({
      id: "SignUpFlow.paymentTitle",
      message: "Try DD+ for free",
    }),
    paymentText: t({
      id: "SignUpFlow.paymentText",
      message: "No commitment. Cancel anytime.",
    }),
    setUpPaymentForLater: t({
      id: "SignUpFlow.setUpPaymentForLater",
      message: "Set up payment for later:",
    }),
    debitCredit: t({
      id: "SignUpFlow.debitCredit",
      message: "Debit/credit",
    }),
    paypal: t({
      id: "SignUpFlow.paypal",
      message: "PayPal",
    }),
  };
}

export default React.memo(SubscriptionPaymentPage);
