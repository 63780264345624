import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { t } from "@lingui/macro";
import Loading from "../../../components/Loading/Loading";
import SubscriptionPlanSelect from "../components/SubscriptionPlanSelect";
import { getLocale } from "../../../utils/getLocale";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { SignUpFlowLayout } from "../SignUpFlowLayout";
import { SignUpFlowLocationType } from "../types/SignUpFlowLocationType";
import { navigateToCredentials } from "../utils/navigateToCredentials";
import { navigate } from "gatsby";
import { SignUpFlowHeader } from "../components/SignUpFlowHeader";
import { CheckMark } from "../icons/CheckMark";
import { SubscribeContinueBlackButton } from "../components/SubscribeContinueBlackButton";
import { PaymentProvider } from "../contexts/PaymentProvider";

const SubscriptionPlanPage = ({ location }: { location?: SignUpFlowLocationType }) => {
  const tt = useTranslations();

  const { loading: loginLoading, user, loggedIn } = useContext(LoginContext);

  const locale = useMemo(() => getLocale(), []);

  const queryParams = useMemo(() => location?.search || "", [location]);

  // Handle logged out user
  useEffect(() => {
    if (!loginLoading && !user && !loggedIn) {
      navigateToCredentials("account");
    }
  }, [locale, loggedIn, loginLoading, queryParams, user]);

  const handleNext = useCallback(async () => {
    navigate(`../payment${queryParams}`, {
      replace: false,
    });
  }, [queryParams]);

  // Full screen loading
  if (loginLoading) {
    return (
      <div style={{ background: "#fff", minHeight: "100vh" }}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <SEOReactHelmet title={tt.planSEOTitle || "Plan select"} noindex />
      <PaymentProvider>
        <SignUpFlowLayout>
          <div className="flex flex-col items-center">
            <SignUpFlowHeader title={tt.planTitle}>
              <ul className="list-none self-start p-0 m-0 mt-4 ">
                <li className="flex items-start mt-1.5">
                  <CheckMark className="shrink-0" />
                  <span className="my-0 ml-2 body-m pr-12 md:pr-0 -mt-1.5">{tt.firstCheck}</span>
                </li>
                <li className="flex items-start mt-3.5">
                  <CheckMark className="shrink-0" />
                  <span className="my-0 ml-2 body-m -mt-1.5">{tt.secondCheck}</span>
                </li>
                <li className="flex items-start mt-3.5">
                  <CheckMark className="shrink-0" />
                  <span className="my-0 ml-2 body-m -mt-1.5">{tt.thirdCheck}</span>
                </li>
              </ul>
            </SignUpFlowHeader>
          </div>

          <SubscriptionPlanSelect />

          {/* Next button */}
          <SubscribeContinueBlackButton
            className="mt-11"
            data-testid="next"
            type="submit"
            value="NEXT"
            onClick={(event) => {
              event.preventDefault();
              handleNext();
            }}
          >
            {tt.startFreeTrial}
          </SubscribeContinueBlackButton>
        </SignUpFlowLayout>
      </PaymentProvider>
    </>
  );
};

function useTranslations() {
  return {
    planSEOTitle: t({
      id: "SignUpFlow.planSEOTitle",
      message: "Payment",
    }),
    planTitle: t({
      id: "SignUpFlow.planTitle",
      message: "Choose your plan",
    }),
    firstCheck: t({
      id: "SignUpFlow.firstCheck",
      message: "You won't be charged until after your free trial.",
    }),
    secondCheck: t({
      id: "SignUpFlow.secondCheck",
      message: "Unlimited access in app and web",
    }),
    thirdCheck: t({
      id: "SignUpFlow.thirdCheck",
      message: "Cancel or change your plan anytime",
    }),
    startFreeTrial: t({
      id: "SignUpFlow.startFreeTrial",
      message: "Start free trial",
    }),
  };
}

export default React.memo(SubscriptionPlanPage);
