import React from "react";
import { SignUpFlowLocationType } from "./types/SignUpFlowLocationType";
import { Router } from "@reach/router";
import Route from "../../components/Route/Route";
import { isBrowser } from "../../utils/ssr";
import { navigateToCredentials } from "./utils/navigateToCredentials";
import AccountSignUpOptionsPage from "./pages/AccountSignUpOptionsPage";
import AccountEmailCreatePage from "./pages/AccountEmailCreatePage";
import SubscriptionPlanPage from "./pages/SubscriptionPlanPage";
import SubscriptionPaymentPage from "./pages/SubscriptionPaymentPage";
import SubscriptionPaymentPageV3 from "./pages/SubscriptionPaymentPageV3";
import SubscriptionPaymentPageV4 from "./pages/SubscriptionPaymentPageV4";
import StripePortal from "./pages/StripePortalRedirectPage";

// Functional component rendering a router for sign-up flow pages, taking pageContext and location props.
const SignUpFlowRouter = ({
  pageContext,
  location,
}: {
  // pageContext contains matchPath and prefix for setting the router's basepath
  pageContext: { matchPath: string; prefix: string };
  // location contains search for setting the search query when navigating to certain pages
  location: SignUpFlowLocationType;
}) => {
  // path is a constant storing the value of prefix from pageContext
  const path = pageContext.prefix;

  // windowPath is the current pathname of the window if client is in browser
  const windowPath = isBrowser() && window.location.pathname;

  // If windowPath is not an expected sign-up flow path, the user is redirected to the account sign-up options page
  if (windowPath) {
    if (
      !windowPath.includes("/account") &&
      !windowPath.includes("/details") &&
      !windowPath.includes("/plan") &&
      !windowPath.includes("/payment") &&
      !windowPath.includes("/stripe")
    ) {
      navigateToCredentials("account", location?.search);
    }
  }

  return (
    <>
      {/* Wrapper around the reach-router Router component, which allows for rendering of different components based on the current path. 
      The basepath prop is set to the value of path, 
      and the primary prop is set to false to disable the automatic scrolling to top behavior of the reach-router Router component */}
      <Router primary={false} basepath={path}>
        <Route component={AccountSignUpOptionsPage} path="/account" />
        <Route component={AccountEmailCreatePage} path="/details" />
        <Route component={SubscriptionPlanPage} path="/plan" />
        <Route component={SubscriptionPaymentPage} path="/payment" />
        <Route component={SubscriptionPaymentPageV3} path="/payment-v3" />
        <Route component={SubscriptionPaymentPageV4} path="/payment-v4" />
        <Route component={StripePortal} path="/stripe" />
      </Router>
    </>
  );
};

// ts-prune-ignore-next
export default React.memo(SignUpFlowRouter);
