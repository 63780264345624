import { useField, FormikProps, ErrorMessage } from "formik";
import * as React from "react";
import { ErrorInputIcon } from "../../icons/ErrorInputIcon";

type TextInputProps = {
  id: string;
  placeholderText: string;
} & FormikProps<{}>;

export const TextInput = ({ placeholderText, id, ...props }: TextInputProps) => {
  const [field, meta] = useField(id);

  return (
    <div className="flex flex-col relative mb-4">
      <input
        placeholder=" "
        tabIndex={0}
        className={`${
          meta.touched && meta.error ? "border-red-100 border-opacity-75 border-2 shadow-md" : "border-grey"
        } ${
          meta.value && !meta.error && "border-green border-opacity-75 border-2 shadow-md"
        } focus:bg-white border border-grey transition-all focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 focus:shadow-md px-3.5 pt-4 pb-3 rounded-lg w-full flex-1 appearance-none placeholder-grey focus:outline-none focus:border-transparent`}
        autoComplete={field.name}
        {...field}
        {...props}
      />
      <label
        htmlFor={id}
        className="text-grey absolute top-0 left-0 py-3.5 px-5 text-lg duration-300 origin-0 w-full cursor-text pointer-events-none select-none"
      >
        {placeholderText}
      </label>

      {meta.touched && meta.error ? (
        <div className="flex items-center mt-1">
          <ErrorInputIcon className="mr-1" />
          <ErrorMessage name={field.name} component="span" className="text-red-100 text-xs block" />
        </div>
      ) : null}
    </div>
  );
};
