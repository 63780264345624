import React from "react";
import { DDBlueIcon } from "../icons/DDBlueIcon";

export function SignUpFlowHeader({
  componentBeforeTitle,
  step,
  title,
  children,
}: {
  componentBeforeTitle?: React.ReactNode;
  step?: string;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <DDBlueIcon height={39} className="self-start mt-2.5 md:hidden" />
      {componentBeforeTitle && componentBeforeTitle}
      {step && (
        <h2 className="text-black opacity-75 text-sm self-start m-0 font-medium mt-4 tracking-wider">
          {step.toUpperCase()}
        </h2>
      )}
      <h1 className="text-black self-start m-0 mt-4 font-normal text-[1.5rem]">{title}</h1>
      {children}
    </>
  );
}
