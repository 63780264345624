import React from "react";
import { default as colors } from "../utils/colors.json";

export default function CheckBoxEmpty(props: any) {
  return (
    <svg
      {...props}
      className="opacity-50 hover:opacity-75 active:opacity-100 block"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="22" height="22" rx="3.25" stroke={colors.GREY_DARK} strokeWidth="1.75" />
    </svg>
  );
}
